import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { CookieService } from 'ngx-cookie-service';
import { User } from './_models';
import { AccountService } from './_services/account.service';
import { MiscService } from './_services/misc.service';
import { marker } from '@ngneat/transloco-keys-manager/marker';
import { TranslocoService } from '@ngneat/transloco';
import { LoaderService } from './_services/loader.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  cookieValue!: string;
  user?: User | null;
  public appPages = [
    { title: 'Admin', url: '/admin' },
    { title: 'Pictures', url: '/pictures' },
    { title: 'Acc. approval', url: '/acc-approval' },
    { title: 'Statistics', url: '/statistics' },
  ];

  constructor(
    private _router: Router,
    public miscService: MiscService,
    public accountService: AccountService,
    private translocoService: TranslocoService,
    public loaderService: LoaderService
  ) {
    marker('error.accountDeleted');
    marker('error.accountSuspended');
    marker('error.badRequest');
    marker('error.emailAlreadyRegistered');
    marker('error.emailNotVerified');
    marker('error.emailVeriCodeInvalid');
    marker('error.emailVeriCodeMissing');
    marker('error.internalServerError');
    marker('error.invalidCredentials');
    marker('error.invalidEmailFormat');
    marker('error.invalidLoginToken');
    marker('error.invalidNameFormat');
    marker('error.invalidPasswordFormat');
    marker('error.tocNotAccepted');

    this.accountService.user.subscribe((x) => (this.user = x));
  }

  setLanguage(event: any) {
    // update the storage language
    this.translocoService.setActiveLang(event.detail.value);
  }

  ngOnInit() {

    if(window.location.origin.includes('localhost')){
      this.accountService.apiUrl ='https://admin.adventures-dev.love69.eu/api';  
    } else {
      this.accountService.apiUrl = window.location.origin + '/api';
    }
    this.miscService.getMetadata();
    const data = this.accountService.getUser().subscribe({
      next: (data) => {
        this.accountService.userSubject.next(data);
      },
      error: (error: any) => {
        console.log('Error caught in component: ', error);
      },
    });
  }

  logout() {
    this.accountService.logout().subscribe((x) => {
      this.accountService.userSubject.next(null);
      this._router.navigate(['/login']);
    });
  }
}
