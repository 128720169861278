import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { environment } from '../../environments/environment';
import { User } from '../_models';
import { CustomHttpService } from './custom-http.service';
@Injectable({ providedIn: 'root' })
export class AccountService {
  public userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;
  public apiUrl = '';

  constructor(private customHttpService: CustomHttpService) {
    this.userSubject = new BehaviorSubject<User | null>(null);
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  getUser(): Observable<any> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/admin/details`,
      {
        withCredentials: true,
      }
    );
  }

  getVerifyAccounts(): Observable<any> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/admin/verify-accounts`,
      {
        withCredentials: true,
      }
    );
  }

  getVerifyIdentity(): Observable<any> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/admin/verify-identity`,
      {
        withCredentials: true,
      }
    );
  }

  getProfileNameChanges(): Observable<any> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/admin/profile-name-changes`,
      {
        withCredentials: true,
      }
    );
  }

  getBioChanges(): Observable<any> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/admin/bio-changes`,
      {
        withCredentials: true,
      }
    );
  }

  getImagesToVerify(): Observable<any> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/admin/verify-images`,
      {
        withCredentials: true,
      }
    );
  }

  getAllUsers(): Observable<any> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/admin/all-user-status`,
      {
        withCredentials: true,
      }
    );
  }
  approveRejectAccount(
    userId: number,
    imageId: number,
    approved: boolean,
    comment?: string
  ): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/admin/verify-accounts`,
      {
        userId,
        imageId,
        approved,
        comment,
      },
      { withCredentials: true }
    );
  }

  approveProfileNameChange(
    changeId: number,
    approved: boolean
  ): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/admin/profile-name-changes`,
      {
        changeId,
        approved,
      },
      { withCredentials: true }
    );
  }

  approveBioChange(changeId: number, approved: boolean): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/admin/bio-changes`,
      {
        changeId,
        approved,
      },
      { withCredentials: true }
    );
  }

  approveImage(imageId: number, approved: boolean): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/admin/verify-images`,
      {
        imageId,
        approved,
      },
      { withCredentials: true }
    );
  }

  approveUserIdentity(verificationId: number, approved : boolean): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/admin/verify-identity`,
      {
        verificationId,
        approved
      },
      { withCredentials: true }
    );
  }

  resetBadImageCounter(userId: number): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/admin/reset-bic`,
      {
        userId,
      },
      { withCredentials: true }
    );
  }

  giveOneMonthSub(userId: number): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/admin/free-subscription`,
      {
        userId,
        months : 1
      },
      { withCredentials: true }
    );
  }

  suspendUser(userId: number, suspended: boolean): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/admin/user-suspended`,
      {
        userId,
        suspended,
      },
      { withCredentials: true }
    );
  }

  deleteUser(userId: number, deleted: boolean): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/admin/user-deleted`,
      {
        userId,
        deleted,
      },
      { withCredentials: true }
    );
  }

  reportBadImage(imageId: number): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/admin/report-bad-image`,
      {
        imageId,
      },
      { withCredentials: true }
    );
  }

  verifyEmail(email: any): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/subscriber/request-email-verification`,
      { email },
      { withCredentials: true }
    );
  }

  login(email: string, password: string): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/auth/login`,
      {
        email,
        password,
      },
      { withCredentials: true }
    );
  }

  logout(): Observable<any> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/auth/logout`,
      {
        withCredentials: true,
      }
    );
  }

  register(user: any): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/admin`,
      user
    );
  }

  updateEmail(email: string, code: number): Observable<any> {
    return this.customHttpService.makePutRequest(
      `${this.apiUrl}/subscriber/update-email`,
      { email: email, verificationCode: code },
      { withCredentials: true }
    );
  }

  sendPhoneOffer(phone: string, sendSms : boolean): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/admin/telephonic-subscription-offer`,
      { phone, sendSms },
      { withCredentials: true }
    );
  }

  createFreeAccount(form:any): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/admin/free-account`,
       form ,
      { withCredentials: true }
    );
  }

  requestPasswordReset(email: any): Observable<any> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/auth/request-password-reset`,
      { email },
      { withCredentials: true }
    );
  }

  resetPassword(form: any): Observable<any> {
    return this.customHttpService.makePutRequest(
      `${this.apiUrl}/auth/reset-password`,
      form,
      { withCredentials: true }
    );
  }

  updatePassword(password: string, confirmPassword: string): Observable<any> {
    return this.customHttpService.makePutRequest(
      `${this.apiUrl}/auth/update-password`,
      { password: password, confirmPassword: confirmPassword },
      { withCredentials: true }
    );
  }

  deleteOwnAccount(): Observable<any> {
    return this.customHttpService.makeDeleteRequest(
      `${this.apiUrl}/admin`,
      {
        withCredentials: true,
      }
    );
  }

  getViewStats(): Observable<any> {
    return this.customHttpService.makeGetRequest(
        `${this.apiUrl}/admin/view-stats-weekly`,
        {
            withCredentials: true
        }
    );
  }
}
