<ion-app>
  <ion-split-pane when="md" contentId="main-content">
    <ion-menu contentId="main-content" type="overlay">
      <ion-content>
        <ion-list id="inbox-list">
          <ion-img
            class="logo"
            src="/assets/images/logo-white.png"
            class="ion-padding-horizontal"
          ></ion-img>
          <div class="items-container" *ngIf="this.accountService.userValue">
            <ion-menu-toggle
              auto-hide="false"
              *ngFor="let p of appPages; let i = index"
            >
              <ion-item
                routerDirection="root"
                [routerLink]="[p.url]"
                lines="none"
                detail="false"
                routerLinkActive="selected"
              >
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
          <div class="buttons">
            <ng-container *ngIf="!accountService.userValue; else userLoggedIn">
              <ion-button
                shape="round"
                color="primary"
                expand="full"
                [routerLink]="['login']"
                >{{ "app.login" | transloco }}</ion-button
              >
              <a class="register" [routerLink]="['register']">{{
                "app.registerNow" | transloco
              }}</a>
            </ng-container>
            <ng-template #userLoggedIn>
              <div class="userLoggedIn">
                <ion-item
                  button
                  lines="none"
                  detail="true"
                  [routerLink]="['/account']"
                  routerLinkActive="selected"
                >
                  <ion-icon
                    size="large"
                    slot="start"
                    name="person-circle-outline"
                  ></ion-icon>
                  <ion-label>{{ "user.profile" | transloco }}</ion-label>
                </ion-item>
                <ion-item button lines="none" detail="true" (click)="logout()">
                  <ion-icon
                    size="large"
                    slot="start"
                    name="log-out-outline"
                  ></ion-icon>
                  <ion-label>{{ "app.logout" | transloco }}</ion-label>
                </ion-item>
              </div>
            </ng-template>
          </div>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
<ngx-loading
  [show]="loaderService.getLoading() === true"
  [config]="{ backdropBorderRadius: '3px' }"
></ngx-loading>
