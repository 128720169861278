import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Metadata } from '../_models/misc-service.model';
import { CustomHttpService } from './custom-http.service';
import { AccountService } from './account.service';

@Injectable({ providedIn: 'root' })
export class MiscService {
  public miscMetadata!: Metadata;
  constructor(private customHttpService : CustomHttpService, public accountService : AccountService) {
  }

  
  loading = new BehaviorSubject<boolean>(false);
  loading$ : Observable<boolean> = this.loading.asObservable();

  errorMessage = new BehaviorSubject<string>('');
  errorMessage$: Observable<string> = this.errorMessage.asObservable();


  getMetadata(){
    return this.customHttpService.makeGetRequest(`${this.accountService.apiUrl}/misc/app-metadata`).subscribe(res =>{
        this.miscMetadata = res;
    })
  }
}
